/* .footer {
  display: flex;
  align-items: center;
  margin: -0.5rem -3.5rem;
  margin-top: -3rem;
  z-index: -1;
}

.f-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 4rem;
  gap: 2rem;
  color: white;
}

.f-icons {
  display: flex;
  gap: 2rem;
}


@media screen and (max-width: 480px) {
  .f-content {
    margin-bottom: 1rem;
    gap: 1rem;
  }

  .footer {
    margin: -0.5rem -1.5rem;
    z-index: 0;
  }

  .f-icons>* {
    width: 2rem;
    height: 2rem;
  }

} */

.footer {
  display: flex;
  align-items: center;
  margin: -0.5rem -3.5rem;
  /* margin-top: -3rem; */
  /* z-index: -1; */
  position: relative;
}

.f-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 4rem;
  gap: 2rem;
  color: white;
  text-align: center;
  /* z-index: 1; */
  /* Center text for smaller screens */
}

.f-icons {
  display: flex;
  gap: 2rem;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {

  .f-content {
    margin-top: 4rem;
    /* Adjust margin for smaller screens */
    gap: 1rem;
    /* Reduce gap between items */
  }

  .f-icons {
    gap: 1rem;
    /* Reduce gap between icons */
  }

  .f-icons>* {
    width: 2rem;
    height: 2rem;
  }

  span {
    font-size: 20px;
    /* Adjust font size for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .f-content {
    margin-top: 3rem;
    /* Further adjust margin for very small screens */
    gap: 0.5rem;
    /* Further reduce gap between items */
  }

  span {
    font-size: 18px;
    /* Further reduce font size for very small screens */
  }

  .f-icons>* {
    width: 1.5rem;
    height: 1.5rem;
  }
}