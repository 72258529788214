.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
}

/* HEADING */
.portfolio span:nth-of-type(1) {
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}

.portfolio span:nth-of-type(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}

/* SLIDER */
.portfolio .swiper {
  overflow: visible !important;
}

.portfolio-slider {
  margin-top: 3rem;
  width: 100%;
}

.portfolio-slider .swiper-slider {
  width: 20rem;
}

.portfolio img {
  width: 19rem;
  filter: drop-shadow(-12px 15px 13px rgba(248, 62, 62, 0.25));
  border-radius: 19px;
  margin-bottom: 50px;
}


@media screen and (max-width: 480px) {

  .portfolio img {
    width: 15rem;
  }
}