.gettingStart_container {
    display: grid;
    grid-template-columns: 2fr 3fr;
    margin: 20px 0px 10px 0px;
    position: relative;
}

.start_button {
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 150px;
    margin: 20px 0px;
    padding: 11px 26px;
    background: linear-gradient(189deg, #fdc50f 26.71%, #fb982f 99.36%);
    box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
}

.get_Started_text1 {
    font-family: 'Galactus';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.07em;
}

.get_Started_text2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
}

.details_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image_style {
    width: 50vw;
}

@media screen and (max-width: 1000px) {
    .gettingStart_container {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {
    .image_style {
        width: 60vw;
    }

    .get_Started_text2 {
        font-size: 30px;
    }
}