.n-wrapper {
  height: 10vh;
  display: flex;
  justify-content: space-between;
}

.n-left {
  flex: 1;
  align-items: center;
  display: flex;
  gap: 1rem;
}

.n-name {
  font-size: 1.3rem;
  font-weight: bold;
}


.n-right {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}

.n-list>ul {
  display: flex;
  gap: 2rem;
  margin-right: 4rem;
  cursor: pointer;
  width: max-content;
}

.n-list>ul>li:hover {
  cursor: pointer;
  color: var(--orange)
}

.n-button {
  flex: 2;
}

.hamburger {
  display: none;
  align-content: center;
}

@media screen and (max-width: 768px) {
  .n-list {
    display: none;
  }

  .n-list>ul {
    width: auto
  }

  .n-right.open .n-list {
    display: block;
    position: absolute;
    top: 10vh;
    /* Below the navbar */
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 10;
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.45);
  }

  .n-right.open ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .n-right.open ul li {
    font-size: 1.5rem;
  }

  .hamburger {
    display: block;
    align-content: center;
    /* Aligns content along the cross axis */
    justify-content: center;
    /* Centers the icon horizontally */
    align-items: center;
    /* Ensures vertical alignment */
    display: flex;
    /* Flexbox to center items */
    height: 100%;
  }

  /* .n-button {
    display: none;
  } */
}

/* MODEL STYLE */
/* Individual user item styling */
.userItem {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
}

/* User avatar styling */
.userAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}