/* AuthPage.css */
.auth-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    align-items: center;
}

.auth-box {
    /* background-color: white; */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.auth-box h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.auth-form {
    display: flex;
    flex-direction: column;
}

.auth-input {
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.auth-input:focus {
    outline: none;
    border-color: #007bff;
}

.auth-button {
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.auth-button:hover {
    background-color: #0056b3;
}

.auth-toggle {
    margin-top: 20px;
}

.auth-toggle span {
    color: #007bff;
    cursor: pointer;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 15px;
}

.success-message {
    font-size: 14px;
    margin-bottom: 15px;
}

/* Some CSS for the Google Sign-In Button */
.google-signin-button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #4285F4;
    /* Google blue */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.google-signin-button:hover {
    background-color: #357ae8;
}