/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal */
.modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    width: 300px;
    text-align: center;
    position: relative;
}

/* Close Button */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: red;
    color: white;
    padding: 5px 10px;
    border-radius: 50%;
    cursor: pointer;
    font-weight: bold;
}

/* Donation Form */
.donation-form {
    display: grid;
    gap: 10px;
}

.donation-form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

/* Donate Button */
.donate-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.donate-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Complete Payment Button */
.complete-payment-button {
    margin-top: 10px;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

/* Thank You Modal */
.thank-you-modal {
    background-color: #f8f9fa;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    width: 300px;
    text-align: center;
}

.thank-you-modal h2 {
    color: #28a745;
}

.thank-you-modal p {
    font-size: 16px;
    color: #333;
}